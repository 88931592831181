import React from 'react'
import CardBox from '../CardBox'
import PageTitle from '../utils/PageTitle'

// react-icon
import { BsDiagram3 } from 'react-icons/bs'
import { CgSmartphoneChip } from 'react-icons/cg'
import { ImStack } from 'react-icons/im'
import { MdImageSearch } from 'react-icons/md'

import useLang from '../../hooks/useLang'
import { labels } from '../../localization/localization'

const WorkProcess = () => {
  const { lang } = useLang()

  return (
    <section className='workProcess_section'>
      {/* page title -- component */}
      <PageTitle title={labels?.howIWork?.[lang]} subTitle={labels?.workingProcess?.[lang]} />
      <div className='py-2'></div>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-3 col-md-6 col-12'>
            <CardBox bgColor='#F1F1F1'>
              <div className='work_box'>
                <MdImageSearch className='icon' />
                <div className='title'>{labels?.discover?.[lang]}</div>
                <div className='description'>{labels?.discoverDescription?.[lang]} </div>
              </div>
            </CardBox>
          </div>
          <div className='col-lg-3 col-md-6 col-12 mt-lg-4 mt-md-0 mt-4'>
            <CardBox bgColor='#ECE5EE'>
              <div className='work_box'>
                <CgSmartphoneChip className='icon' />
                <div className='title'>{labels?.idea?.[lang]}</div>
                <div className='description'>{labels?.ideaDescription?.[lang]} </div>
              </div>
            </CardBox>
          </div>
          <div className='col-lg-3 col-md-6 col-12 mt-lg-0 mt-4'>
            <CardBox bgColor='#E4E8E3'>
              <div className='work_box'>
                <BsDiagram3 className='icon' />
                <div className='title'>{labels?.prototype?.[lang]}</div>
                <div className='description'>{labels?.prototypeDescription?.[lang]} </div>
              </div>
            </CardBox>
          </div>
          <div className='col-lg-3 col-md-6 col-12 mt-lg-4 mt-4'>
            <CardBox bgColor='#E6F6F6'>
              <div className='work_box'>
                <div className='icon'>
                  <ImStack className='icon' />
                </div>
                <div className='title'>{labels?.implement?.[lang]}</div>
                <div className='description'>{labels?.implementDescription?.[lang]} </div>
              </div>
            </CardBox>
          </div>
        </div>
      </div>
    </section>
  )
}

export default WorkProcess
