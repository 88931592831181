import React from "react";

const Input = (props) => {
  return (
    <div className="custom_input">
      <input {...props} className="form-control" />
    </div>
  );
};

export default Input;
