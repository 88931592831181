import React from 'react'
import PageTitle from '../utils/PageTitle'
import CardBox from './../CardBox'
import Count from './../utils/Count'

// react-icon
import { SiGmail } from 'react-icons/si'

// react-bootstrap
import { Form } from 'react-bootstrap'

// custom input component
import Input from '../utils/Input'
import Button from './../utils/Button'
import TextArea from './../utils/TextArea'

//  formik for validation contact schema
import { Formik } from 'formik'

// @ import custom Function for todo validation on Change
import OnChangeValidation from '../../functions/OnChangeValidation'

// custom validation schema
import contactSchema from '../../config/schema/contactSchema'

import useLang from '../../hooks/useLang'
import { labels } from '../../localization/localization'

const Contact = () => {
  const { lang } = useLang()
  const { handleActiveValidation, activeValidation } = OnChangeValidation()

  return (
    <section className='contact_section pb-5'>
      {/* page title -- component */}
      <PageTitle title={labels?.contact?.[lang]} subTitle={labels?.lookingForACreativeWebsite?.[lang]} />
      <div className='py-3'></div>
      <div className='container pb-3'>
        <div className='row'>
          <div className='col-lg-6   col-12 mb-lg-0 mb-5'>
            <div className='text_title text_primary'>
              {labels?.whatWill?.[lang]} <br />
              {labels?.beNextStep?.[lang]}
            </div>
            {labels?.youAreOneStepCloseToBuild?.[lang]}
            <div className='py-3'></div>
            <div className='contact_sideBox gap-3 mb-5'>
              <Count count='01' />
              <div>
                <div className='text_title'>{labels?.iWillSendProposal?.[lang]}</div>
                {labels?.getAComprehensivePropostal?.[lang]}
              </div>
            </div>
            <div className='contact_sideBox gap-3 mb-5'>
              <Count count='02' />
              <div>
                <div className='text_title'>{labels?.togetherDiscussAndFinalize?.[lang]}</div>
                {labels?.workCloselyTogetherToMakeSureTheProposal?.[lang]}
              </div>
            </div>
            <div className='contact_sideBox gap-3'>
              <Count count='03' />
              <div>
                <div className='text_title'>{labels?.letStartBuilding?.[lang]}</div>
                {labels?.startThrillingProcess?.[lang]}
              </div>
            </div>
          </div>
          <div className='col-lg-6   col-12'>
            <CardBox bgColor='#ECE5EE'>
              <div className='d-flex gap-4'>
                <SiGmail />
                <div className='text_black'>{labels?.writeAFewWords?.[lang]}</div>
              </div>
            </CardBox>
            <CardBox bgColor='#F0F0F0'>
              <div className='row'>
                <Formik
                  initialValues={{
                    name: '',
                    phone: '',
                    email: '',
                    subject: '',
                    message: '',
                  }}
                  validateOnChange={activeValidation}
                  validateOnBlur={activeValidation}
                  onSubmit={(data, { resetForm }) => {
                    console.log(data)
                  }}
                  // validate custom validation schema
                  validationSchema={contactSchema}
                >
                  {({ errors, handleChange, handleSubmit, values }) => {
                    return (
                      <Form onSubmit={(e) => handleSubmit(e)}>
                        <div className='row pt-2'>
                          <div className='col-md-6 col-12 mb-3'>
                            <Input
                              placeholder={labels?.yourName?.[lang]}
                              name='name'
                              value={values.name}
                              onChange={handleChange}
                            />
                            <small className='input_suggestion_error'>{errors.name}</small>
                          </div>
                          <div className='col-md-6 col-12 mb-3'>
                            <Input
                              placeholder={labels?.yourEmail?.[lang]}
                              name='email'
                              value={values.email}
                              onChange={handleChange}
                            />
                            <small className='input_suggestion_error'>{errors.email}</small>
                          </div>
                          <div className='col-md-6 col-12 mb-3'>
                            <Input
                              placeholder={labels?.phoneNumber?.[lang]}
                              name='phone'
                              value={values.phone}
                              onChange={handleChange}
                            />{' '}
                            <small className='input_suggestion_error'>{errors.phone}</small>
                          </div>
                          <div className='col-md-6 col-12 mb-3'>
                            <Input
                              placeholder={labels?.subject?.[lang]}
                              name='subject'
                              value={values.subject}
                              onChange={handleChange}
                            />
                            <small className='input_suggestion_error'>{errors.subject}</small>
                          </div>
                          <div className='col-12 mb-3'>
                            <TextArea
                              placeholder={labels?.message?.[lang]}
                              style={{ height: '120px' }}
                              name='message'
                              value={values.message}
                              onChange={handleChange}
                            />
                            <small className='input_suggestion_error'>{errors.message}</small>
                          </div>
                          <div className='col-12 mb-3'>
                            <Button style={{ width: '100%' }} type='submit' onClick={handleActiveValidation}>
                              {labels?.send?.[lang]}
                            </Button>
                          </div>
                          <div className='col-12 text_black'>
                            {labels?.ifYouNeedFastResponse?.[lang]} &nbsp;
                            <a href='mailto:bijayashah551@gmail.com'>bijayashah551@gmail.com</a>
                          </div>
                        </div>
                      </Form>
                    )
                  }}
                </Formik>
              </div>
            </CardBox>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Contact
