export const labels = {
    en: {
        en: 'EN',
        ne: 'अंग्रेजी'
    },
    ne: {
        en: 'NE',
        ne: 'ने'
    },
    bijaYaSHah: {
        en: 'BijaYa SHah',
        ne: 'बिजय शाह'
    },
    bijayaShah: {
        en: 'Bijaya Shah',
        ne: 'बिजय शाह'
    },
    iam: {
        en: 'I am',
        ne: 'म हो'
    },
    visionaryProjectManager: {
        en: 'Visionary Project Manager | Creative FullStack Developer',
        ne: 'दृष्टिकोन परियोजना प्रबन्धक | रचनात्मक फुल स्ट्याक डेभलपर'
    },
    myDescription: {
        en: `Experienced MERN Full Stack Developer, also adept at project management and team leadership. Let's build something amazing together!`,
        ne: `अनुभवी MERN फुल स्ट्याक डेभलपर, परियोजना प्रबन्धन र टोल नेतृत्वमा पनि निपुण छ। हामी साथै केहि अद्भुत निर्माण गरौं!`
    },
    hiThere: {
        en: 'Hi There',
        ne: 'नमस्ते'
    },
    hireMe: {
        en: 'Hire Me',
        ne: 'मलाई नियुक्त गर्नुहोस्'
    },
    viewPortfolio: {
        en: 'View Portfolio',
        ne: 'पोर्टफोलियो हेर्नुहोस्'
    },
    facebook: {
        en: 'FACEBOOK',
        ne: 'फेसबुक'
    },
    instagram: {
        en: 'INSTAGRAM',
        ne: 'इन्स्टाग्राम'
    },
    github: {
        en: 'GITHUB',
        ne: 'गिटहब'
    },
    linkedin: {
        en: 'LINKEDIN',
        ne: 'लिंक्डइन'
    },
    class: {
        en: 'class',
        ne: 'क्लास'
    },
    me: {
        en: 'Me',
        ne: 'म'
    },
    constructor: {
        en: 'constructor()',
        ne: 'निर्माता()'
    },
    render: {
        en: 'render()',
        ne: 'रेन्डर()'
    },
    return: {
        en: 'return(',
        ne: 'फर्क('
    },
    div: {
        en: '<div>',
        ne: '<डिभ>'
    },
    thisName: {
        en: 'this.name',
        ne: 'यो.नाम'
    },
    thisRole: {
        en: 'this.role',
        ne: 'यो.भूमिका'
    },
    PM: {
        en: 'PM',
        ne: 'पीएम'
    },
    TL: {
        en: 'TL',
        ne: 'टिएल'
    },
    MERN: {
        en: 'MERN',
        ne: 'मर्न'
    },
    DevOps: {
        en: 'DevOps',
        ne: 'डेभअप्स'
    },
    newDate: {
        en: 'new Date()',
        ne: 'नयाँ मिति()'
    },
    getFullYear: {
        en: 'getFullYear()',
        ne: 'वर्ष मिति()'
    },
    '1999': {
        en: '1999',
        ne: '१९९९'
    },
    thisAge: {
        en: 'this.age',
        ne: 'यो.उमेर'
    },
    thisExperience: {
        en: 'this.experience',
        ne: 'यो.अनुभव'
    },
    thisProject: {
        en: 'this.project',
        ne: 'यो.परियोजना'
    },
    thisCompany: {
        en: 'this.company',
        ne: 'यो.कम्पनी'
    },
    plus30Complete: {
        en: '30+ complete',
        ne: '३०+ पूरा'
    },
    plus4Year: {
        en: '4 year +',
        ne: '४ वर्ष +'
    },
    informationCarePvtLtd: {
        en: 'Information Care Pvt. Ltd.',
        ne: 'इन्फर्मेशन केयर प्रा. लि.'
    },
    home: {
        en: 'Home',
        ne: 'गृहपृष्ठ'
    },
    aboutMe: {
        en: 'About Me',
        ne: 'मेरो बारेमा'
    },
    services: {
        en: 'Services',
        ne: 'सेवाहरू'
    },
    projects: {
        en: 'Projects',
        ne: 'परियोजनाहरू'
    },
    blog: {
        en: 'Blog',
        ne: 'ब्लग'
    },
    mySuccessRate: {
        en: 'My Success Rate',
        ne: 'मेरो सफलता दर'
    },
    startFrom: {
        en: 'Starts from',
        ne: 'यहाँबाट सुरु गर्दछ'
    },
    startFrom2020: {
        en: 'Starts from 2020',
        ne: '२०२० बाट सुरु'
    },
    happyClients: {
        en: 'Happy Clients',
        ne: 'खुशी ग्राहकहरू'
    },
    plus20Happy: {
        en: '20+ Happy',
        ne: '२०+ खुशी'
    },
    plus30SuccessfulProject: {
        en: '30+ Successful Project',
        ne: '३०+ सफल परियोजना'
    },
    plus20SuccessfulProject: {
        en: '20+ Successful Project',
        ne: '२०+ सफल परियोजना'
    },
    implement: {
        en: 'Implement',
        ne: 'कार्यान्वयन'
    },
    design: {
        en: 'Design',
        ne: 'डिजाइन'
    },
    skillsAndTools: {
        en: 'Skills & Tools',
        ne: 'सिप र उपकरणहरू'
    },
    myToolBoxAndThingsICanDo: {
        en: 'MY TOOLBOX & THINGS I CAN DO',
        ne: 'मेरो उपकरण बक्स र मैले गर्न सक्ने कुराहरू'
    },
    thieSkillsToolsAndTechnologiesIUseToBringYourProductsToLife: {
        en: `The skills, tools and technologies I use to bring your products to life:`,
        ne: `तपाईंको उत्पादनहरूलाई जीवनमा ल्याउनका लागि मैले प्रयोग गर्ने कौशलहरू, उपकरण र प्रविधिहरू:`
    },
    workingProcess: {
        en: `Working Process`,
        ne: `कामको प्रक्रिया`
    },
    howIWork: {
        en: "HOW I WORK",
        ne: "म कसरी काम गर्छु"
    },
    discover: {
        en: "Discover",
        ne: "खोज्नु"
    },
    idea: {
        en: "Idea",
        ne: "विचार"
    },
    prototype: {
        en: "Prototype",
        ne: "प्रोटोटाइप"
    },
    discoverDescription: {
        en: "Explore the core of your project by delving deeply into its objectives, potential, and purpose.",
        ne: "तपाईंको परियोजनाको मूल उद्देश्य, सम्भावना र उद्देश्यमा गहिरो गरेर तपाईंको परियोजनाको मूलमा खोज्नुहोस्।"
    },
    ideaDescription: {
        en: "Convert findings into creative notions by generating and honing ideas that speak to you.",
        ne: "आविष्कारहरूलाई तपाईंले बोलाउने र उत्कृष्ट विचारहरू उत्पन्न गरेर र तेजी गरेर रूपान्तरण गर्नुहोस्।"
    },
    prototypeDescription: {
        en: "Create concrete representations of concepts so they may be seen and improved upon.",
        ne: "अवधारणाहरूको ठोस प्रतिनिधित्व बनाउनुहोस् भने तिनीहरूलाई हेर्न र सुधार गर्न सकिन्छ।"
    },
    implementDescription: {
        en: 'With accuracy, converting into solid solutions, guaranteeing smooth functionality and integration.',
        ne: 'सटिकतासँग, ठोस समाधानमा रूपान्तरण गर्दै, मुलुक संचालन र एकीकरण सुनिश्चित गर्दछ।'
    },
    whyChosseMe: {
        en: 'Why Choose Me',
        ne: 'मलाई किन छनो गर्नुहोस्'
    },
    myProductiveServicesIsReady: {
        en: 'MY PRODUCTIVE SERVICES IS READY',
        ne: 'मेरो उत्पादक सेवाहरू तयार छन्'
    },
    whyChooseMeDescription: {
        en: "Personalized solutions, smooth communication, constant assistance—your vision, my dedication",
        ne: "व्यक्तिगत समाधानहरू, मुलुक संचार, लगातार सहायता - तपाईंको दृष्टिकोन, मेरो समर्पण"
    },
    customerAssistance: {
        en: "Customer Assistance",
        ne: "ग्राहक सहायता"
    },
    dedicatedSupportTailoredToYourNeeds: {
        en: "Dedicated support tailored to your needs, ensuring your satisfaction and success.",
        ne: "तपाईंको आवश्यकताहरूमा अनुरूप विशेष समर्थन, तपाईंको संतोष र सफलताको सुनिश्चित गर्दछ।"
    },
    affordablePrice: {
        en: "Affordable Price",
        ne: "सस्तो मुल्य"
    },
    qualitySoluctionWithoutBreakingTheBank: {
        en: "Quality solutions without breaking the bank",
        ne: "बैंक तोड्न बिना गुणस्तरीय समाधानहरू"
    },
    globalPresence: {
        en: "Global Presence",
        ne: "वैश्विक उपस्थिति"
    },
    bringingExpertiseToClientsWorldwide: {
        en: "Bringing expertise to clients worldwide.",
        ne: "ग्राहकहरूको विश्वभरी विशेषज्ञता ल्याउने।"
    },
    proBuildingServices: {
        en: "Pro Building Services",
        ne: "प्रो निर्माण सेवाहरू"
    },
    professionalCraftsmanshipForYourProjects: {
        en: "Professional craftsmanship for your projects.",
        ne: "तपाईंको परियोजनाका लागि पेशेवर शिल्पकला।"
    },
    innovativeIdeas: {
        en: "Innovative Ideas",
        ne: "नवाचारी विचारहरू"
    },
    pushingBoundariesToDeliverFreshSolutions: {
        en: "Pushing boundaries to deliver fresh solutions.",
        ne: "नयाँ समाधानहरू प्रस्तुत गर्नका लागि सीमाहरू चापिंदै।"
    },
    contentMarketing: {
        en: "Content Marketing",
        ne: "सामग्री विपणन"
    },
    elevatingYourBrandThroughStrategicContent: {
        en: "Elevating your brand through strategic content.",
        ne: "योजनात्मक सामग्रीद्वारा तपाईंको ब्रान्डलाई उच्चारण गर्दै।"
    },
    lookingForACreativeWebsite: {
        en: 'Looking For A Creative - Responsive Website ?',
        ne: 'क्रिएटिभ रिस्पोन्सिभ वेबसाइट चाहिन्छ ?'
    },
    contact: {
        en: 'Contact',
        ne: 'सम्पर्क'
    },
    whatWill: {
        en: 'What will',
        ne: 'के गर्ने'
    },
    beNextStep: {
        en: 'be the next step?',
        ne: 'अर्को कदम के हुनेछ?'
    },
    youAreOneStepCloseToBuild: {
        en: "You are one step closer to build you perfect website",
        ne: "तपाईंको पर्खाल वेबसाइट निर्माण गर्नका लागि एक कदम नजिक छन्"
    },
    iWillSendProposal: {
        en: "I will send you proposal for website",
        ne: "म तपाईंलाई वेबसाइटको प्रस्ताव पठाउनेछु"
    },
    getAComprehensivePropostal: {
        en: "Get a comprehensive proposal that outlines the objectives and scope of your project.",
        ne: "तपाईंको परियोजनाको उद्देश्य र दायरा उल्लेख गर्दछ एक विस्तृत प्रस्ताव प्राप्त गर्नुहोस्।"
    },
    togetherDiscussAndFinalize: {
        en: "Together discuss and finalize",
        ne: "संयुक्तसभा गर्नुहोस् र अन्तिम गर्नुहोस्"
    },
    workCloselyTogetherToMakeSureTheProposal: {
        en: "Work closely together to make sure the proposal is refined and completely fits your vision.",
        ne: "प्रस्ताव सुधारित र तपाईंको दृष्टिकोणसँग पूर्णतापूर्वक मेल खान्छ।"
    },
    letStartBuilding: {
        en: "Let’s start building",
        ne: "बनाउन सुरु गरौं"
    },
    startThrillingProcess: {
        en: "Start the thrilling process of making your website come to life, with professional assistance at every turn.",
        ne: "तपाईंको वेबसाइट जीवनमा आउने रोमाञ्चक प्रक्रिया सुरु गर्नुहोस्, प्रत्येक कोनामा पेशेवर सहायता सहित।"
    },
    writeAFewWords: {
        en: "Write a few words about your project and we’ll prepare a proposal for you within 24 hours",
        ne: "तपाईंको परियोजनाबारे केहि शब्द लेख्नुहोस् र हामी तपाईंको लागि २४ घण्टाभित्र एक प्रस्ताव तयार गर्नेछौं"
    },
    yourName: {
        en: "Your Name",
        ne: "तपाईंको नाम"
    },
    yourEmail: {
        en: "Your Email",
        ne: "तपाईंको इमेल"
    },
    phoneNumber: {
        en: "Phone Number",
        ne: "फोन नम्बर"
    },
    subject: {
        en: "Subject",
        ne: "विषय"
    },
    message: {
        en: "Message",
        ne: "सन्देश"
    },
    send: {
        en: "Send",
        ne: "पठाउनुहोस्"
    },
    ifYouNeedFastResponse: {
        en: "If you need to have fast response. Just contact me at ",
        ne: "जति छिटो प्रतिक्रिया चाहिन्छ। म संपर्क गर्नुहोस्"
    },
    "2024": {
        en: "2024",
        ne: "२०२४"
    },
    allRightReserved: {
        en: "All Rights Reserved",
        ne: "सर्वाधिकार सुरक्षित"
    },
}
