import React from "react";
// home page -- component
import LandingPage from "./../components/pages/LandingPage";
// import Service from "./../components/pages/Service";
// import Project from "./../components/pages/Project";
// import Blog from "./../components/pages/Blog";
import Contact from "../components/pages/Contact";
import Tools from "../components/pages/Tools";
import WorkProcess from "../components/pages/WorkProcess";
import SuccessRate from "./../components/pages/SuccessRate";
import WhyChoose from "./../components/pages/WhyChoose";
import SectionPadding from "./../components/utils/SectionPadding";

const Home = () => {
  return (
    <div className="home_section">
      <LandingPage />

      <div style={{paddingTop:'2rem'}}>
        <SuccessRate />
      </div>

      <SectionPadding />
      <Tools />

      <SectionPadding />
      <WorkProcess />

      <SectionPadding />
      <WhyChoose />
      <SectionPadding />
      {/* Project*/}
      {/* <Project /> */}

      <Contact />
      {/* <SectionPadding /> */}
    </div>
  );
};

export default Home;
