import React from 'react'
import CardBox from './../CardBox'
import PageTitle from './../utils/PageTitle'

// react-icon
import { BiRupee } from 'react-icons/bi'
import { BsFileEarmarkBarGraph } from 'react-icons/bs'
import { HiOutlineSupport } from 'react-icons/hi'
import { MdElectricalServices, MdMiscellaneousServices } from 'react-icons/md'
import { TbWorldUpload } from 'react-icons/tb'

import useLang from '../../hooks/useLang'
import { labels } from '../../localization/localization'

const WhyChoose = () => {
  const { lang } = useLang()

  return (
    <section className='whyChoose_section'>
      {/* page title -- component */}
      <PageTitle title={labels?.myProductiveServicesIsReady?.[lang]} subTitle={labels?.whyChosseMe?.[lang]} />
      <div className='py-2'></div>
      <div className='container'>
        <div className='pt-3'></div>
        <div className='row'>
          <div className='col-lg-4 col-md-6 col-12 mb-3'>
            <CardBox bgColor='#F1F1F1' className='d-flex gap-3'>
              <HiOutlineSupport />
              <div className=''>
                <div className='text_title'>{labels?.customerAssistance?.[lang]} </div>
                <div className='pt-2'></div>
                {labels?.dedicatedSupportTailoredToYourNeeds?.[lang]}
              </div>
            </CardBox>
          </div>
          <div className='col-lg-4 col-md-6 col-12 mb-3'>
            <CardBox bgColor='#ECE5EE' className='d-flex gap-3'>
              <BiRupee />
              <div className=''>
                <div className='text_title'>{labels?.affordablePrice?.[lang]}</div> <div className='pt-2'></div>
                {labels?.qualitySoluctionWithoutBreakingTheBank?.[lang]}
              </div>
            </CardBox>
          </div>
          <div className='col-lg-4 col-md-6 col-12 mb-3'>
            <CardBox bgColor='#E6F6F6' className='d-flex gap-3'>
              <TbWorldUpload />
              <div className=''>
                <div className='text_title'>{labels?.globalPresence?.[lang]} </div> <div className='pt-2'></div>
                {labels?.bringingExpertiseToClientsWorldwide?.[lang]}
              </div>
            </CardBox>
          </div>
          <div className='col-lg-4 col-md-6 col-12 mb-3'>
            <CardBox bgColor='#F1F1F1' className='d-flex gap-3'>
              <MdMiscellaneousServices />
              <div className=''>
                <div className='text_title'>{labels?.proBuildingServices?.[lang]} </div> <div className='pt-2'></div>
                {labels?.professionalCraftsmanshipForYourProjects?.[lang]}
              </div>
            </CardBox>
          </div>
          <div className='col-lg-4 col-md-6 col-12 mb-3'>
            <CardBox bgColor='#ECE5EE' className='d-flex gap-3'>
              <MdElectricalServices />
              <div className=''>
                <div className='text_title'>{labels?.innovativeIdeas?.[lang]} </div> <div className='pt-2'></div>
                {labels?.pushingBoundariesToDeliverFreshSolutions?.[lang]}
              </div>
            </CardBox>
          </div>
          <div className='col-lg-4 col-md-6 col-12 mb-3'>
            <CardBox bgColor='#E6F6F6' className='d-flex gap-3'>
              <BsFileEarmarkBarGraph />
              <div className=''>
                <div className='text_title'>{labels?.contentMarketing?.[lang]}</div> <div className='pt-2'></div>
                {labels?.elevatingYourBrandThroughStrategicContent?.[lang]}
              </div>
            </CardBox>
          </div>
        </div>
      </div>
    </section>
  )
}

export default WhyChoose
