import React from 'react'
// react-icons
import { AiFillCopyrightCircle, AiFillFacebook, AiFillGithub, AiFillInstagram, AiFillLinkedin } from 'react-icons/ai'

import useLang from '../../../hooks/useLang'
import { labels } from '../../../localization/localization'

const Footer = () => {
  const { lang } = useLang()

  return (
    <section className='footer_section'>
      <div className='container'>
        <div className='footer_box'>
          {/* copyright text */}
          <div className='copyright'>
            <AiFillCopyrightCircle /> {labels?.[2024]?.[lang]}. {labels?.allRightReserved?.[lang]}
          </div>

          {/* side map */}
          <div className='footer_items'>
            <span className='footer_item'>{labels?.home?.[lang]}</span>
            <span className='footer_item'>{labels?.aboutMe?.[lang]}</span>
            <span className='footer_item'>{labels?.services?.[lang]}</span>
            <span className='footer_item'>{labels?.projects?.[lang]}</span>
            <span className='footer_item'>{labels?.blog?.[lang]}</span>
          </div>

          <div className='footer_social'>
            <AiFillFacebook className='icon_sm' />
            <AiFillInstagram className='icon_sm' />
            <AiFillGithub className='icon_sm' />
            <AiFillLinkedin className='icon_sm' />
          </div>

          {/* social link - icon */}
        </div>
      </div>
    </section>
  )
}

export default Footer
