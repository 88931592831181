import { createContext, useEffect, useState } from 'react'

// Create a context to manage language settings.
export const LanguageContext = createContext({
  lang: 'ne',
  setLang: () => {},
})

// A component to provide and manage language settings.
const LanguageProvider = ({ children }) => {
  // Initialize the language state with a default value from local storage or 'ne'.
  const [language, setLanguage] = useState(localStorage.getItem('lang') || 'ne')

  // Update local storage whenever the language state changes.
  useEffect(() => {
    localStorage.setItem('lang', language)
  }, [language])

  return (
    // Provide the language state and setter function to child components.
    <LanguageContext.Provider value={{ lang: language, setLang: setLanguage }}>{children}</LanguageContext.Provider>
  )
}

export default LanguageProvider
