import React from 'react'
// react-bootstrap component
import Container from 'react-bootstrap/Container'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import Offcanvas from 'react-bootstrap/Offcanvas'
// react - router-dom
import { Link } from 'react-router-dom'
import { convertToDevanagari } from '../../../functions/convertToDevanagari'
import useLang from '../../../hooks/useLang'
import { labels } from '../../../localization/localization'
import Count from '../../utils/Count'
import Button from './../../utils/Button'

const NavBar = () => {
  const { lang } = useLang()

  return (
    <>
      {['lg'].map((expand) => (
        <Navbar key={expand} sticky='top' expand={expand}>
          <Container>
            <Navbar.Brand as={Link} to={'/'}>
              {labels?.bijaYaSHah?.[lang]}
            </Navbar.Brand>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement='end'
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>BijaYa SHah</Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className='justify-content-end flex-grow-1 pe-3'>
                  <Nav.Link as={Link} to={'#'}>
                    <Count count={convertToDevanagari('01.', lang)} /> {labels?.home?.[lang]}
                  </Nav.Link>
                  <Nav.Link as={Link} to={'/#'}>
                    <Count count={convertToDevanagari('02.', lang)} /> {labels?.aboutMe?.[lang]}
                  </Nav.Link>
                  <Nav.Link as={Link} to={'/#'}>
                    <Count count={convertToDevanagari('03.', lang)} /> {labels?.services?.[lang]}
                  </Nav.Link>
                  <Nav.Link as={Link} to={'/#'}>
                    <Count count={convertToDevanagari('04.', lang)} /> {labels?.projects?.[lang]}
                  </Nav.Link>
                  <Nav.Link as={Link} to={'/#'}>
                    <Count count={convertToDevanagari('05.', lang)} /> {labels?.blog?.[lang]}
                  </Nav.Link>
                  <Nav.Link as={Link} to={'/#'} className='hireMe_btn'>
                    <Button>{labels?.hireMe?.[lang]}</Button>
                  </Nav.Link>
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      ))}
    </>
  )
}

export default NavBar
