import React from "react";

const PageTitle = (props) => {
  return (
    <section className="pageTitle_section">
      <div className="subTitle">{props.subTitle}</div>
      <div className="title">{props.title}</div>
    </section>
  );
};
export default PageTitle;
