import React from "react";

const TextArea = (props) => {
  return (
    <div className="custom_input">
      <textarea {...props} className="form-control" />
    </div>
  );
};

export default TextArea;
