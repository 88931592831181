import React from "react";

const CardBox = (props) => {
  return (
    <div className="cardBox_section" style={{ background: props.bgColor }}>
      <div {...props}></div>
    </div>
  );
};

export default CardBox;
