import React from 'react'
import useLang from '../../hooks/useLang'

export const LanguageToggle = () => {
  const { lang, setLang } = useLang()

  const handleSwitchLanguage = () => {
    setLang(lang === 'en' ? 'ne' : 'en')
  }

  return (
    <div
      className='language_toggle'
      onClick={() => {
        handleSwitchLanguage()
      }}
    >
      {lang === 'en' ? 'NP' : 'EN'}
    </div>
  )
}
