// custom scss file
import "./assets/styles/main.scss";

// react-bootstrap
import "bootstrap/dist/css/bootstrap.min.css";

// react-router-dom
import { Navigate, Routes, Route } from "react-router-dom";
import MainLayout from "./components/mainLayout/index";
import Home from "./pages/Home";

function App() {
  return (
    <>
      <Routes>
        <Route path="" element={<MainLayout />}>
          <Route path="/" element={<Home />} />

          {/* redirect unknown page to home page */}
          <Route path="*" element={<Navigate to="/" />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
