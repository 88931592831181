import { Outlet } from 'react-router-dom'
import { LanguageToggle } from '../utils/LanguageToggle'
import Footer from './templates/Footer'
import NavBar from './templates/NavBar'

const MainLayout = () => {
  return (
    <div>
      {/* custom - navbar */}
      <NavBar />
      <div className='mainLayout_section'>
        <Outlet />
      </div>
      <Footer />
      <div className='language_sticky'>
        <LanguageToggle />
      </div>
    </div>
  )
}

export default MainLayout
