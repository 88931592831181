import React from 'react'

const Button = (props) => {
  // pass variant as a props for
  // primary button or outline button
  return (
    <>
      {props.variant === 'outline' ? (
        <button {...props} className='primary_outline_btn'></button>
      ) : (
        <button {...props} className='primary_btn'></button>
      )}
    </>
  )
}

export default Button
