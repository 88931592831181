import React from 'react'
import CardBox from './../CardBox'
import PageTitle from './../utils/PageTitle'

// react-icon
import { AiOutlineFileProtect, AiOutlineFlag } from 'react-icons/ai'
import { IoLogoBuffer } from 'react-icons/io'
import { RiEmotionHappyLine } from 'react-icons/ri'
import useLang from '../../hooks/useLang'
import { labels } from '../../localization/localization'

const SuccessRate = () => {
  const { lang } = useLang()

  return (
    <section className='successRate_section'>
      {/* page title -- component */}
      <PageTitle title={labels?.mySuccessRate?.[lang]} />
      <div className='py-2'></div>
      <div className='container'>
        <div className='row success_box'>
          <div className='col-lg-3 col-md-6 col-12 mb-lg-0 mb-3'>
            <CardBox bgColor='#F1F1F1'>
              <div className='d-flex gap-3'>
                <AiOutlineFlag />
                <div className=''>
                  <div className='text_title'> {labels?.startFrom?.[lang]}</div>
                  {labels?.startFrom2020?.[lang]}
                </div>
              </div>
            </CardBox>
          </div>
          <div className='col-lg-3 col-md-6 col-12 mb-lg-0 mb-3'>
            <CardBox bgColor='#ECE5EE'>
              <div className='d-flex gap-3'>
                <RiEmotionHappyLine />
                <div className=''>
                  <div className='text_title'>{labels?.happyClients?.[lang]}</div>
                  {labels?.plus20Happy?.[lang]}
                </div>
              </div>
            </CardBox>
          </div>
          <div className='col-lg-3 col-md-6 col-12 mb-lg-0 mb-3'>
            <CardBox bgColor='#E4E8E3'>
              <div className='d-flex gap-3'>
                <AiOutlineFileProtect />
                <div className=''>
                  <div className='text_title'>{labels?.projects?.[lang]}</div>
                  {labels?.plus30SuccessfulProject?.[lang]}
                </div>
              </div>
            </CardBox>
          </div>
          <div className='col-lg-3 col-md-6 col-12 mb-lg-0 mb-3'>
            <CardBox bgColor='#E6F6F6'>
              <div className='d-flex gap-3'>
                <IoLogoBuffer />
                <div className=''>
                  <div className='text_title'>{labels?.implement?.[lang]}</div>
                  {labels?.plus20SuccessfulProject?.[lang]}
                </div>
              </div>
            </CardBox>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SuccessRate
