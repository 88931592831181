// @ text constant

const text = {
  NAME_REQUIRED: "Name is required",
  PHONE_REQUIRED: "Phone number is required",
  EMAIL_REQUIRED: "Email address is required",
  EMAIL_VALID: "Please enter valid email address",
  MESSAGE_REQUIRED: "Message is required",
  VALID_PHONE: "Please enter valid phone number",
  SUBJECT_REQUIRED: "Subject is required",
};
export default text;
