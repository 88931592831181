import React from 'react'
import Button from './../utils/Button'
// react-icon
import { FaLongArrowAltRight } from 'react-icons/fa'

// react-icon
import { BsGithub, BsInstagram } from 'react-icons/bs'
import { FaFacebookSquare, FaLinkedin } from 'react-icons/fa'
import { convertToDevanagari, digitLabels } from '../../functions/convertToDevanagari'
import useLang from '../../hooks/useLang'
import { labels } from '../../localization/localization'

const LandingPage = () => {
  const { lang } = useLang()

  return (
    <section className='landing_section'>
      <div className='container'>
        <div className='row landing_box'>
          <div className='col-lg-6 col-12 welcome_box  mt-md-0'>
            <div className='triangle_button'>
              <div className='button'>{labels?.hiThere?.[lang]}</div>
              <div className='T_button'></div>
            </div>
            <div className='text_large'>
              {labels?.iam?.[lang]}
              {lang === 'en' ? (
                <span className='font_wight_1000' style={{ textTransform: 'upperCase' }}>
                  {labels?.bijayaShah?.en}
                </span>
              ) : (
                labels?.bijayaShah?.ne
              )}
            </div>
            <div className='text_medium'>"{labels?.visionaryProjectManager?.[lang]}"</div>
            <div className='py-4'></div>
            {labels?.myDescription?.[lang]}
            <div className='py-4'></div>
            <div className='d-flex gap-3' style={{ textTransform: 'upperCase' }}>
              <Button>
                {labels?.hireMe?.[lang]} <FaLongArrowAltRight />
              </Button>
              <Button variant='outline'>
                <div className='cta' style={{ textTransform: 'upperCase' }}>
                  {labels?.viewPortfolio?.[lang]} <FaLongArrowAltRight />
                </div>
              </Button>
            </div>
            <div className='py-4'></div>
            {/* social link */}
            <div className='social_link'>
              <div className='icon'>
                <FaFacebookSquare />
                {labels?.facebook?.[lang]}
              </div>
              <div className='icon'>
                <BsInstagram /> {labels?.instagram?.[lang]}
              </div>
              <div className='icon'>
                <BsGithub /> {labels?.github?.[lang]}
              </div>
              <div className='icon'>
                <FaLinkedin /> {labels?.linkedin?.[lang]}
              </div>
            </div>
          </div>

          {/* coding box */}
          <div className='col-lg-6  col-12 home_coding_box  '>
            <div className='primary_box '>
              {digitLabels?.[1]?.[lang]}. {labels?.class?.[lang]} <strong>{labels?.me?.[lang]}</strong> &#123; <br />
              {digitLabels?.[2]?.[lang]}. &emsp; {labels?.constructor?.[lang]} &#123;
              <br />
              {digitLabels?.[3]?.[lang]}. &emsp; &emsp; {labels?.thisName?.[lang]} = "
              <strong style={{ textTransform: 'uppercase' }}>{labels?.bijayaShah?.[lang]}</strong>"; <br />
              {digitLabels?.[4]?.[lang]}. &emsp; &emsp; {labels?.thisRole?.[lang]} = ["
              <strong>{labels?.PM?.[lang]}</strong>", "<strong>{labels?.TL?.[lang]}</strong>", "
              <strong>{labels?.MERN?.[lang]}</strong>","
              <strong>{labels?.DevOps?.[lang]}</strong>"];
              <br />
              {digitLabels?.[5]?.[lang]}. &emsp; &emsp; {labels?.thisAge?.[lang]} = {labels?.newDate?.[lang]}.
              {labels?.getFullYear?.[lang]} - {labels?.[1999]?.[lang]}; <br />
              {digitLabels?.[6]?.[lang]}. &emsp; &emsp; {labels?.thisExperience?.[lang]} = "
              <strong>{labels?.plus4Year?.[lang]}</strong>";
              <br />
              {digitLabels?.[7]?.[lang]}. &emsp; &emsp; {labels?.thisProject?.[lang]} = "
              <strong>{labels?.plus30Complete?.[lang]}</strong>";
              <br />
              {digitLabels?.[8]?.[lang]}. &emsp; &emsp; {labels?.thisCompany?.[lang]} = "
              <strong>{labels?.informationCarePvtLtd?.[lang]}</strong>";
              <br />
              {digitLabels?.[9]?.[lang]}. &emsp; &#125; <br />
              {convertToDevanagari('10', lang)}. &#125;
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default LandingPage
