
export const digitLabels = {
  0: {
    en: '0',
    ne: '०',
  },
  1: {
    en: '1',
    ne: '१',
  },
  2: {
    en: '2',
    ne: '२',
  },
  3: {
    en: '3',
    ne: '३',
  },
  4: {
    en: '4',
    ne: '४',
  },
  5: {
    en: '5',
    ne: '५',
  },
  6: {
    en: '6',
    ne: '६',
  },
  7: {
    en: '7',
    ne: '७',
  },
  8: {
    en: '8',
    ne: '८',
  },
  9: {
    en: '9',
    ne: '९',
  },
}
export function convertToDevanagari(text, lang) {
  const string = text?.toString()
  let devanagariYear = ''

  for (let i = 0; i < string?.length; i++) {
    const digit = string[i]

    if (digitLabels[digit]?.[lang]) devanagariYear += digitLabels[digit][lang]
    else devanagariYear += digit
  }

  return devanagariYear
}
