import React from 'react'
import PageTitle from '../utils/PageTitle'

// tool-icon
import img27 from '../../assets/images/tools/AWS.svg'
import img19_1 from '../../assets/images/tools/Bash-Dark.svg'
import img4 from '../../assets/images/tools/Bootstrap.svg'
import img31 from '../../assets/images/tools/C.svg'
import img32 from '../../assets/images/tools/CPP.svg'
import img2 from '../../assets/images/tools/CSS.svg'
import img27_1 from '../../assets/images/tools/Cloudflare-Dark.svg'
import img30 from '../../assets/images/tools/Discord.svg'
import img29 from '../../assets/images/tools/Docker.svg'
import img15_2 from '../../assets/images/tools/ExpressJS-Dark.svg'
import img6 from '../../assets/images/tools/Figma-Dark.svg'
import img28 from '../../assets/images/tools/Firebase-Dark.svg'
import img26 from '../../assets/images/tools/GCP-Dark.svg'
import img23 from '../../assets/images/tools/Git.svg'
import img25 from '../../assets/images/tools/GitLab-Dark.svg'
import img24 from '../../assets/images/tools/Github-Dark.svg'
import img1 from '../../assets/images/tools/HTML.svg'
import img21_1 from '../../assets/images/tools/Heroku.svg'
import img7 from '../../assets/images/tools/Illustrator.svg'
import img17_2 from '../../assets/images/tools/JQuery.svg'
import img12 from '../../assets/images/tools/JavaScript.svg'
import img19 from '../../assets/images/tools/Linux-Dark.svg'
import img5 from '../../assets/images/tools/MaterialUI-Dark.svg'
import img17_1 from '../../assets/images/tools/MongoDB.svg'
import img17 from '../../assets/images/tools/MySQL-Dark.svg'
import img21 from '../../assets/images/tools/Netlify-Dark.svg'
import img22 from '../../assets/images/tools/Nginx.svg'
import { default as img15_1, default as img20 } from '../../assets/images/tools/NodeJS-Dark.svg'
import img18 from '../../assets/images/tools/PHP-Dark.svg'
import img8 from '../../assets/images/tools/Photoshop.svg'
import img16 from '../../assets/images/tools/PostgreSQL-Dark.svg'
import img14 from '../../assets/images/tools/React-Dark.svg'
import img15 from '../../assets/images/tools/Redux.svg'
import img2_1 from '../../assets/images/tools/Sass.svg'
import img5_2 from '../../assets/images/tools/StackOverflow-Dark.svg'
import img3 from '../../assets/images/tools/TailwindCSS-Dark.svg'
import img13 from '../../assets/images/tools/TypeScript.svg'
import img11 from '../../assets/images/tools/VIM-Dark.svg'
import img10 from '../../assets/images/tools/VSCode-Dark.svg'
import img9 from '../../assets/images/tools/VisualStudio-Dark.svg'
import img5_1 from '../../assets/images/tools/Wordpress.svg'
import useLang from '../../hooks/useLang'
import { labels } from '../../localization/localization'

const Tools = () => {
  const { lang } = useLang()

  const toolIcon = [
    { img: img1 },
    { img: img2 },
    { img: img2_1 },
    { img: img3 },
    { img: img4 },
    { img: img5 },
    { img: img5_1 },
    { img: img5_2 },
    { img: img6 },
    { img: img7 },
    { img: img8 },
    { img: img9 },
    { img: img10 },
    { img: img11 },
    { img: img12 },
    { img: img13 },
    { img: img14 },
    { img: img15 },
    { img: img15_1 },
    { img: img15_2 },
    { img: img16 },
    { img: img17 },
    { img: img17_1 },
    { img: img17_2 },
    { img: img18 },
    { img: img19 },
    { img: img19_1 },
    { img: img20 },
    { img: img21 },
    { img: img21_1 },
    { img: img22 },
    { img: img23 },
    { img: img24 },
    { img: img25 },
    { img: img26 },
    { img: img27 },
    { img: img27_1 },
    { img: img28 },
    { img: img29 },
    { img: img30 },
    { img: img31 },
    { img: img32 },
  ]
  return (
    <div className='tools_section'>
      {/* page title -- component */}
      <PageTitle title={labels?.myToolBoxAndThingsICanDo?.[lang]} subTitle={labels?.skillsAndTools?.[lang]} />
      <div className='py-2'></div>
      <div className='container'>
        {labels?.thieSkillsToolsAndTechnologiesIUseToBringYourProductsToLife?.[lang]}
        <div className='tools_box pt-3 gap-4'>
          {toolIcon.map((data, index) => {
            return <img src={data.img} alt='tool icon' key={index} />
          })}
        </div>
      </div>
    </div>
  )
}

export default Tools
